<template>
  <div class="other_settings">
    <ul class="tabs_settings">
      <li
        v-for="tab in tabs"
        :key="tab.id"
        :class="{ 'li--active': tab.id === activeTab.id }"
        @click="activeTab = tab"
      >
        {{ tab.label }}
      </li>
    </ul>
    <template v-for="(data, key) in dataOherSettings">
      <TextInputSettings
        v-if="activeTab.id === data.tabId"
        :data="{ ...data, value: settings[data.field] } || 0"
        :key="key"
        @onInputValue="value => $emit('onInputValue', value)"
      />
    </template>
  </div>
</template>

<script>
import TextInputSettings from '@/components/ui/TextInputSettings';
export default {
  name: 'OthetSettings',
  data() {
    return {
      activeTab: {
        label: 'Постоянные',
        id: 'const',
      },
      tabs: [
        {
          label: 'Постоянные',
          id: 'const',
        },
        {
          label: 'Переменные',
          id: 'let',
        },
      ],
    };
  },
  props: {
    dataOherSettings: Array,
    settings: Object,
  },
  components: {
    TextInputSettings,
  },
};
</script>

<style lang="scss" scoped>
.other_settings {
  margin-bottom: 20px;
}
.tabs_settings {
  display: flex;
  margin-bottom: 14px;
  li {
    list-style: none;
    margin-right: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #7b8c93;
    cursor: pointer;
    transition: all 0.3s ease;
    &:last-child {
      margin-right: 0;
    }
  }
  .li--active {
    border-bottom: 3px solid #435a64;
    color: #435a64;
  }
}
</style>
