<template>
  <div>
    <h2>Звук</h2>
    <div class="music_effects">
      <InputRangeMusic
        :data="dataMusic"
        @changeSongs="songValue => $emit('changeSongs', { music: songValue })"
      />
      <InputRangeMusic
        :data="dataEffects"
        @changeSongs="songValue => $emit('changeSongs', { effects: songValue })"
      />
    </div>
  </div>
</template>

<script>
import InputRangeMusic from '@/components/ui/InputRangeMusic';

export default {
  name: 'SongSettings',
  props: {
    songs: Object,
  },
  data() {
    return {
      dataMusic: {
        heading: 'Музыка',
        value: null,
      },
      dataEffects: {
        heading: 'Эффекты',
        value: null,
      },
    };
  },
  created() {
    if (this.songs.music) {
      this.dataMusic.value = this.songs.music;
    }
    if (this.songs.effects) {
      this.dataEffects.value = this.songs.effects;
    }
  },
  components: {
    InputRangeMusic,
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #435a64;
  margin-bottom: 30px;
}
.music_effects {
  display: flex;
  .range_input:first-child {
    margin-right: 117px;
  }
}
</style>
