<template>
  <div class="text_input-settings">
    <p v-html="data.label"></p>
    <input
      :value="data.value"
      @input="
        e =>
          $emit('onInputValue', { value: +e.target.value, field: data.field })
      "
      type="number"
    />
  </div>
</template>

<script>
export default {
  name: 'TextInputSettings',
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped></style>
