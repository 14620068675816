<template>
  <div class="select_input h-48">
    <div class="select--custom" @click="isOpen = true" v-if="!isOpen">
      {{ chooseLang.label }}
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z"
          fill="#7B8C93"
        />
      </svg>
    </div>
    <div class="lang" v-else>
      <div v-for="lang in languages" class="lang-radio" :key="lang.code">
        <div class="svg">
          <svg
            v-show="lang.code === chooseLang.code"
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.54998 13.0001L0.849976 7.3001L2.27498 5.8751L6.54998 10.1501L15.725 0.975098L17.15 2.4001L6.54998 13.0001Z"
              fill="#435A64"
            />
          </svg>
        </div>
        <label
          @click="chooseLang = lang"
          :class="{ active: chooseLang.code === lang.code }"
          >{{ lang.label }}</label
        >
      </div>
      <button @click="handleSaveLang" class="btn--green">Сохранить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectInput',
  props: {
    lang: String,
  },
  mounted() {
    if (this.lang) {
      this.chooseLang = this.languages.filter(
        language => language.code === this.lang,
      )[0];
    }
  },
  methods: {
    handleSaveLang() {
      this.isOpen = false;
      this.$emit('saveLang', this.chooseLang.code);
    },
  },
  data() {
    return {
      isOpen: false,
      chooseLang: {
        code: 'ru',
        label: 'Русский',
      },
      languages: [
        {
          code: 'ru',
          label: 'Русский',
        },
        {
          code: 'en',
          label: 'Английский',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.h-48 {
  height: 48px;
}
</style>
