<template>
  <div class="range_input">
    <p>{{ data.heading }}</p>
    <div class="range-row">
      <div class="range-row-input">
        <input
          class="styled-slider slider-progress"
          v-model="data.value"
          @change="e => $emit('changeSongs', +e.target.value)"
          min="0"
          max="100"
          type="range"
          ref="range"
        />
      </div>
      <div class="svg-box">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.51172 0.425781C10.0352 0.753906 11.2891 1.53906 12.2734 2.78125C13.2578 4 13.75 5.40625 13.75 7C13.75 8.59375 13.2578 10.0117 12.2734 11.2539C11.2891 12.4727 10.0352 13.2461 8.51172 13.5742V12.0273C9.61328 11.6992 10.5039 11.0781 11.1836 10.1641C11.8867 9.25 12.2383 8.19531 12.2383 7C12.2383 5.80469 11.8867 4.75 11.1836 3.83594C10.5039 2.92188 9.61328 2.30078 8.51172 1.97266V0.425781ZM10.375 7C10.375 8.40625 9.75391 9.41406 8.51172 10.0234V3.97656C9.02734 4.23437 9.46094 4.65625 9.8125 5.24219C10.1875 5.82813 10.375 6.41406 10.375 7ZM0.25 4.75H3.23828L7 0.988281V13.0117L3.23828 9.25H0.25V4.75Z"
            fill="#435A64"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputRangeMusic',
  props: {
    data: Object,
  },
  mounted() {
    if (this.data.value) {
      this.$refs.range.value = this.data.value;
    }
    this.selectRange();
  },
  methods: {
    selectRange() {
      for (let e of document.querySelectorAll(
        'input[type="range"].slider-progress',
      )) {
        e.style.setProperty('--value', e.value);
        e.style.setProperty('--min', e.min == '' ? '0' : e.min);
        e.style.setProperty('--max', e.max == '' ? '100' : e.max);
        e.addEventListener('input', () =>
          e.style.setProperty('--value', e.value),
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
