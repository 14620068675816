<template>
  <div class="balance">
    <h2>Баланс</h2>
    <TextInputSettings
      v-for="(data, key) in dataBalance"
      :data="data"
      :key="key"
    />
  </div>
</template>

<script>
import TextInputSettings from '@/components/ui/TextInputSettings';
export default {
  name: 'Baalance',
  data() {
    return {
      dataBalance: [
        {
          label: 'Количество удачи для получения сундука',
          value: 50,
        },
        {
          label: 'Среднее количество раундов для игры',
          value: 50,
        },
        {
          label: 'Максимальный уровень Hamster',
          value: 50,
        },
        {
          label:
            'Максимальное количество выигрышей для получения улучшения&nbsp;Hamster',
          value: 50,
        },
        {
          label: 'Цена нового Hamster',
          value: 50,
        },
      ],
    };
  },
  components: {
    TextInputSettings,
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #435a64;
  margin-bottom: 20px;
}
.balance {
  margin-bottom: 30px;
}
</style>
