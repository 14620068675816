<template>
  <div>
    <label>Язык в игре</label>
    <SelectInput :lang="lang" @saveLang="code => $emit('saveLang', code)" />
  </div>
</template>

<script>
import SelectInput from '@/components/ui/SelectInput';

export default {
  name: 'ChooseLanguage',
  props: {
    lang: String,
  },
  components: {
    SelectInput,
  },
};
</script>

<style scoped>
label {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #435a64;
  margin-bottom: 20px;
  display: block;
}
</style>
