<template>
  <div>
    <div v-if="Object.keys(settings).length" class="settings">
      <h2>Настройки</h2>
      <ChooseLanguage
        :lang="settings.lang"
        @saveLang="code => (settings.lang = code)"
      />
      <hr />
      <SongSettings
        :songs="{
          music: settings.music,
          effects: settings.effects,
        }"
        @changeSongs="obj => handleChangeSong(obj)"
      />
      <hr />
      <!--  <Balance />-->
      <OthetSettings
        :settings="settings"
        :dataOherSettings="dataOtherSettings"
        @onInputValue="val => (settings[val.field] = val.value)"
      />
      <div class="btns-group">
        <button class="btn--green" @click="saveSettings" :disabled="isLoading">
          Сохранить
        </button>
      </div>
      <div v-if="isLoading">
        <loader
          object="#fff"
          color1="#435A64"
          color2="#ECEFF0"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          name="circular"
        ></loader>
      </div>
    </div>
    <div class="settings" v-else>
      <h2>Настройки пусты</h2>
    </div>
  </div>
</template>

<script>
import ChooseLanguage from '@/components/ChooseLanguage';
import SongSettings from '@/components/SongSettings';
import Balance from '@/components/Baalance';
import OthetSettings from '@/components/OthetSettings';
import { mapActions, mapState } from 'vuex';
import { loader } from 'vue-ui-preloader';

export default {
  name: 'Settings',
  async mounted() {
    await this.getSettings();
  },
  data() {
    return {
      dataOtherSettings: [
        {
          label: 'Минимальный коэффициент очков бонуса за уровень Hamster',
          field: 'min_bonus_coefficient',
          tabId: 'const',
        },
        {
          label: 'Максимальный коэффициент, увеличивающий награду удачи',
          field: 'max_coefficient_for_luck',
          tabId: 'const',
        },
        {
          label: 'Максимальный уровень Hamster',
          field: 'hamster_max_level',
          tabId: 'const',
        },
        {
          label: 'Минимальная удача',
          field: 'min_luck',
          tabId: 'const',
        },
        {
          label: 'Среднее количество раундов игры',
          field: 'game_average_rounds_count',
          tabId: 'const',
        },
        {
          label:
            'Требуемый опыт для возможности перехода на следующий уровень Hamster',
          field: 'new_level_expirence',
          tabId: 'const',
        },
        {
          label: 'Единица энергии',
          field: 'energy',
          tabId: 'const',
        },
        {
          label: 'Количество раздач за сутки',
          field: 'per_day_distributions_number',
          tabId: 'const',
        },
        {
          label:
            'Количество очков удачи которые вычитают у пользователя при получении Лутбокса',
          field: 'loutbox_points',
          tabId: 'const',
        },
      ],
    };
  },
  methods: {
    ...mapActions(['getSettings', 'saveSettings']),
    handleChangeSong(obj) {
      Object.keys(obj).map(key => {
        this.settings[key] = obj[key];
      });
    },
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      isLoading: state => state.isLoading,
    }),
  },
  components: {
    ChooseLanguage,
    SongSettings,
    Balance,
    OthetSettings,
    loader,
  },
};
</script>

<style lang="scss" scoped>
.settings {
  width: 100%;
  max-width: 1100px;
  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #435a64;
    margin-bottom: 30px;
  }
  hr {
    border: 1px solid #eceff0;
    margin: 50px 0;
  }
  .btns-group {
    justify-content: flex-end;
    button {
      width: 240px;
      height: 60px;
    }
  }
}
</style>
